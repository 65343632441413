<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import i18n from "@/i18n";
import { sessionHelper, ddlHelper } from "@/utils";
import { SESSION, SHARED } from "@/constants";
import { mapState } from "vuex";
import { USER_MODULE } from '@/state/modules/user'
import { userMethods } from "@/state/helpers";

export default {
  page: {
    title: i18n.t("label.listingX", [i18n.t('module.user')]),
  },
  beforeRouteLeave(to, from, next) {
     if (to.name != 'User-update' && to.name != 'User-create') {
      sessionHelper.clearSearch(SESSION.USER_SEARCH)
    }
    next();
  },
  created() {
    this.initSearchCriteria();
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: i18n.t("label.listingX", [i18n.t('module.user')]),
      items: [
        {
          text: i18n.t("module.dashboard"),
          href: "/",
        },
        {
          text: i18n.t("module.user"),
          active: true,
        },
      ],
      statusDdl: ddlHelper.getActivationStatus(true),
      accountLockStatusDdl: ddlHelper.getAccountLockStatus(true),
      currentUser: sessionHelper.getUserEmail(),
      currentPage: 1,
      filter: "",
      enabled: false,
      perPage: SHARED.DEFAULT_PAGE_SIZE,
      pageOptions: [10, 25, 50, 100],
      filterOn: [],
      sortBy: "name",
      fields: [
        { key: "name", sortable: true, label: i18n.t("label.name"), },
        { key: "email", sortable: true, label: i18n.t("label.email"), },
        { key: "isLocked", sortable: true, label: i18n.t("label.accountStatus"), },
        { key: "isActive", sortable: true, label: i18n.t("label.userStatus"), },
        { key: "Action", sortable: false, label: i18n.t("label.action"), },
      ],
    };
  },
  computed: {
    ...mapState(USER_MODULE, ['status']),
    tableRows() {
      return this.$store.state.user.list.data;
    },
    pagination() {
      return this.$store.state.user.list.pagination;
    },
    totalPage() {
      return this.$store.state.user.list.pagination.total;
    },
  },
  mounted() {},
  methods: {
    ...userMethods,
    initSearchCriteria() {
      let isDefault = true;

      if (sessionHelper.hasSearchCriteria(SESSION.USER_SEARCH)) {
        isDefault = false;
        this.searchCriteria = sessionHelper.retrieveSearch(SESSION.USER_SEARCH);
      }

      if (isDefault) {
        this.searchCriteria = {
          pageNumber: SHARED.DEFAULT_PAGE_NUMBER,
          pageSize: SHARED.DEFAULT_PAGE_SIZE,
          isActive: this.statusDdl[0].value,
          isLocked: this.accountLockStatusDdl[0].value,
          searchValue: "",
          searchArray: [],
        };
      }
      this.getUserList(this.searchCriteria);
    },

    getUserList(filter) {
      this.getAllUser({ filter });
      // this.$store.dispatch(`user/getAllUser`, { filter });
      this.searchCriteria = filter;
      sessionHelper.storeSearch(SESSION.USER_SEARCH, filter);
    },

    pageNavigation(pageNumber) {
      let filter = sessionHelper.retrieveSearch(SESSION.USER_SEARCH);
      filter.pageNumber = pageNumber;

      this.getUserList(filter);
    },

    searchListing() {
        this.searchCriteria.pageNumber = SHARED.DEFAULT_PAGE_NUMBER;

        this.searchCriteria.searchArray = [];
        this.searchCriteria.searchArray.push(this.filter);
        this.getUserList(this.searchCriteria);
    },

    editStatus(user) {
      const data = {
        id: user.id,
        isActive: user.isActive,
      };

      this.updateUserStatus({ filter: this.searchCriteria, data });
    },

     toDetailsPage(id) {
      let filter = sessionHelper.retrieveSearch(SESSION.USER_SEARCH)
      filter.selectedRecordId = id
      sessionHelper.storeSearch(SESSION.USER_SEARCH, filter)

      this.$router.push({
        name: 'User-update'
      })
    },

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                  style="float: left"
                >
                  <label class="d-inline-flex align-items-center">
                   {{$t("label.search")}}
                    <b-form-input
                      v-model="filter"
                      type="search"
                      :placeholder="$t('label.search')"
                      class="form-control form-control-sm ml-2"
                      @change="searchListing"
                    ></b-form-input>
                  </label>
                </div>
              </div>

              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                  style="float: right"
                >
                  <router-link
                    tag="a"
                    to="/user/create"
                    style="float: right; margin-bottom: 10px"
                    class="btn btn-primary w-md waves-effect waves-light"
                  >
                    {{$t('label.createX', [$t('module.user')])}}
                  </router-link>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                :items="tableRows"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
              >
                  <template #cell(isActive)="data">
                  <b-form-checkbox
                    @change="editStatus(tableRows[data.index])"
                    v-model="tableRows[data.index].isActive"
                    switch
                    class="mb-1"
                    :disabled="currentUser == tableRows[data.index].email "
                  >
                  </b-form-checkbox>
                </template>

                <template #cell(isLocked)="data">
                  <span v-if="tableRows[data.index].isLocked == true"
                    >{{$t('label.Lock')}}</span
                  >
                  <span v-else>{{$t('label.active')}}</span>
                </template>

                <template #cell(action)="data">
                  <a
                    href="javascript:void(0);"
                    :class="
                      currentUser == tableRows[data.index].email
                        ? 'disabled text-warning'
                        : 'text-primary'
                    "
                    @click="toDetailsPage(tableRows[data.index].id)"
                    v-b-tooltip.hover
                    title="Edit"
                  >
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <!-- <a
                    href="javascript:void(0);"
                    :class="
                      currentUser == tableRows[data.index].email
                        ? 'disabled text-warning'
                        : 'text-danger'
                    "
                    v-b-tooltip.hover
                    title="Delete"
                    v-if="tableRows[data.index].isActive == true"
                    @click="editStatus(tableRows[data.index])"
                  >
                    <i class="mdi mdi-trash-can font-size-18"></i>
                  </a> -->

                  <!-- <a
                    href="javascript:void(0);"
                    :class="
                      currentUser == tableRows[data.index].email
                        ? 'disabled text-warning'
                        : 'text-success'
                    "
                    v-b-tooltip.hover
                    title="Delete"
                    v-else
                    @click="editStatus(tableRows[data.index])"
                  >
                    <i
                      class="mdi mdi-backup-restore font-size-18"
                      :disabled="currentUser == tableRows[data.index].email"
                    ></i>
                  </a> -->
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalPage"
                      :per-page="perPage"
                      @change="pageNavigation"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
a.disabled {
  pointer-events: none;
  cursor: default;
}
</style>